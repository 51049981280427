import { useMemo } from 'react'
import { kebabCase } from 'lodash'
import { Toast, toastTypes } from 'ui-kit/widgets/Toast'
import { useSelector, useDispatch } from 'react-redux'

import { push as pushToast, remove as removeToast, clear as clearToast } from './actions'
import { State } from './types'

// Toasts
export const useToast = () => {
  const dispatch = useDispatch()
  const helpers = useMemo(() => {
    const push = (toast: Toast) => {
      dispatch(pushToast(toast))
    }

    return {
      toastError: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.DANGER, title, description })
      },
      toastInfo: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.INFO, title, description })
      },
      toastSuccess: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.SUCCESS, title, description })
      },
      toastWarning: (title: string, description?: string) => {
        return push({ id: kebabCase(title), type: toastTypes.WARNING, title, description })
      },
      push,
      remove: (id: string) => dispatch(removeToast(id)),
      clear: () => dispatch(clearToast()),
    }
  }, [dispatch])

  return helpers
}

// Block
export const useBlock = () => {
  return useSelector((state: State) => state.block)
}

export const useTimestamp = () => {
  return Math.round(new Date().getTime() / 1000)
}

export const useInitialBlock = () => {
  return useSelector((state: State) => state.block.initialBlock)
}
