import React from 'react'
import { useWeb3React } from '@web3-react/core'
import { useTranslation } from 'contexts/Localization'
import Container from 'components/layout/Container'
import { truncateAddress } from 'utils/addressHelpers'
import MenuItems from 'config/menu/menu-items.json'
import { Button } from '../Button'
import useAuth from '../../../hooks/useAuth'
import { useModal } from '../../widgets/Modal'
import { useWalletModal } from '../../widgets/WalletModal'
import { AccountModal } from '../../widgets/WalletModal/AccountModal'
import logo from '../../../assets/logo.png'
import {
  HeaderLayout,
  Logo,
  LogoContainer,
  HeaderCenterContainer,
  HeaderLeftContainer,
  HeaderRightContainer,
  HeaderLink,
} from './layouts'
import HeaderMobile from '../HeaderMobile'

const Header = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout)
  const [onPresentAccountModal] = useModal(<AccountModal account={account} logout={logout} />, false)

  return (
    <Container>
      <HeaderMobile />
      <HeaderLayout>
        <HeaderLeftContainer>
          <LogoContainer to="/">
            <Logo src={logo} alt="logo" />
          </LogoContainer>
        </HeaderLeftContainer>
        <HeaderCenterContainer>
          {MenuItems.map((item) => (
            <HeaderLink
              key={item.id}
              to={item.to}
              smooth
            >
              {item.emoji} {t(item.text)}
            </HeaderLink>
          ))}
        </HeaderCenterContainer>
        <HeaderRightContainer>
          {account ? (
            <Button scale="sm" onClick={onPresentAccountModal}>
              {truncateAddress(account)}
            </Button>
          ) : (
            <Button scale="sm" onClick={onPresentConnectModal}>
              {t('Unlock Wallet')}
            </Button>
          )}
        </HeaderRightContainer>
      </HeaderLayout>
    </Container>
  )
}

export default Header
