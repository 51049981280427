// @ts-nocheck
import React from 'react'
import styled from 'styled-components'
import Button from '../../components/Button/Button'
import Text from '../../components/Text/Text'
import LinkExternal from '../../components/Link/LinkExternal'
import Flex from '../../components/Box/Flex'
import { Modal } from '../Modal'
import CopyToClipboard from './CopyToClipboard'
import { connectorLocalStorageKey } from '../../../config/constants'

interface Props {
  account: string
  logout: () => void
}

const LogoutButton = styled(Button)`
  &:hover:not(:disabled):not(.avalon-button--disabled):not(.avalon-button--disabled):not(:active) {
    background-color: ${({ theme }) => theme.colors.primaryDark};
  }
`

export const AccountModal: React.FC<Props> = ({ account, logout, onDismiss = () => null }) => (
  <Modal title="Your wallet" onDismiss={onDismiss}>
    <Text
      fontSize="20px"
      bold
      style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', marginBottom: '8px' }}
    >
      {account}
    </Text>
    <Flex mb="32px">
      <LinkExternal small href={`${process.env.REACT_APP_EXPLORER}/address/${account}`} mr="16px">
        {`View on ${process.env.REACT_APP_CHAIN_NAME} Explorer`}
      </LinkExternal>
      <CopyToClipboard toCopy={account}>Copy Address</CopyToClipboard>
    </Flex>
    <Flex justifyContent="center">
      <LogoutButton
        scale="sm"
        variant="secondary"
        onClick={() => {
          logout()
          window.localStorage.removeItem(connectorLocalStorageKey)
          onDismiss()
        }}
      >
        Logout
      </LogoutButton>
    </Flex>
  </Modal>
)

export default AccountModal
