import styled from 'styled-components'

export default styled.div`
  position: relative;
  max-width: 100vw;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .wrapper {
    width: 100%;
    position: relative;
    align-items: flex-end;
    display: flex;
    justify-content: center;
    margin-top: 50px;
  }

  .road-map-wrapper {
    margin-bottom: 150px;
  }

  .footer-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-items: center;
    margin-bottom: 15px;

    display: flex;
    justify-content: space-around;

    max-width: 1400px;
  }

  .footer-item-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    align-items: end;

    @media only screen and (max-width: 470px) {
      display: none;
    }
  }

  .footer-image {
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 104;
    width: 100%;
    position: absolute;
    z-index: -1;
  }

  .footer-item {
    color: #fff;

    padding: 10px 15px;
    cursor: pointer;
    font-weight: bold;
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    text-decoration: none;
    white-space: nowrap;
    &:hover {
      transform: rotate(2.69deg);
    }
    ${({ theme }) => theme.mediaQueries.xxs} {
      padding: 5px 5px;
      font-size: 15px;
    }
    ${({ theme }) => theme.mediaQueries.md} {
      padding: 10px 15px;
      font-size: 15px;
    }
    ${({ theme }) => theme.mediaQueries.lg} {
      font-size: 18px;
    }
  }

  .copyright {
    color: #fff;
    font-weight: bold;
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
  }
`
