/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react'
import { useWeb3React } from '@web3-react/core'
import { useTranslation } from 'contexts/Localization'
import styled from 'styled-components'
import farmLogo from 'assets/logo.png'
import { truncateAddress } from 'utils/addressHelpers'
import MenuItems from 'config/menu/menu-items.json'
import { useModal } from '../../widgets/Modal'
import { useWalletModal } from '../../widgets/WalletModal'
import useAuth from '../../../hooks/useAuth'
import { Button } from '../Button'
import { AccountModal } from '../../widgets/WalletModal/AccountModal'
import { Logo, LogoContainer } from './layouts'
import { HeaderLink } from '../Header/layouts'

interface OverlayContainerProps {
  isOpen: boolean
}

const OverlayContainer = styled.div<OverlayContainerProps>`
  .menu_overlay {
    width: 100vw;
    height: 100%;
    position: absolute;
    overflow: hidden;
    color: #fff;
    background: #c94b4b;
    background: -webkit-linear-gradient(to right, #92d0cc, #a6d3d1);
    background: linear-gradient(to right, #92d0cc, #a6d3d1);
    z-index: 1;

    // not a good idea for safari browsers. :/
    // ${(props) => props.isOpen && 'transform: translateX(0)'};
    // ${(props) => !props.isOpen && 'transform: translateX(110%)'};

    ${(props) => props.isOpen && 'transform: scale(1, 1)'};
    ${(props) => !props.isOpen && 'transform: scale(1, 0);'};

    transition: transform 0.5s ease;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  .menu_overlay::-webkit-scrollbar {
    display: none;
  }

  .menu_overlay .fas {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 2.5rem;
    transition: all 0.3s ease;
  }

  .menu_overlay .fas:hover {
    color: ${({ theme }) => theme.colors.primary};
    transform: scale(1.1) rotate(180deg);
  }

  #menuBtn {
    display: none;
  }

  .fas {
    margin: 0px 15px;
    font-size: 2rem;
    color: #222;
  }

  .fas:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  .menu_overlay_items {
    display: flex;
    padding-top: 10vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`

export const MobileHeaderLink = styled(HeaderLink)`
  font-size: 1.5em;
`

export const MobileLogoContainer = styled(LogoContainer)`
  width: 25vh;
`

const MobileOverlayMenu = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { t } = useTranslation()
  const { account } = useWeb3React()
  const { login, logout } = useAuth()
  const { onPresentConnectModal } = useWalletModal(login, logout)
  const [onPresentAccountModal] = useModal(<AccountModal account={account} logout={logout} />, false)

  const handleClick = () => {
    setIsOpen(false)
  }

  return (
    <OverlayContainer isOpen={isOpen}>
      <input type="checkbox" name="" id="menuBtn" defaultChecked={isOpen} onChange={() => setIsOpen(!isOpen)} />
      <div className="menu_overlay">
        <label htmlFor="menuBtn">
          <i className="fas fa-times" />
        </label>
        <div className="menu_overlay_items">
          <MobileLogoContainer to="/">
            <Logo src={farmLogo} alt="logo" />
          </MobileLogoContainer>
          {MenuItems.map((item) => (
            <MobileHeaderLink
              onClick={handleClick}
              key={item.id}
              to={item.to}
              scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
            >
              {item.emoji} {t(item.text)}
            </MobileHeaderLink>
          ))}
          <br />
          {account ? (
            <Button scale="sm" onClick={onPresentAccountModal}>
              {truncateAddress(account)}
            </Button>
          ) : (
            <Button scale="sm" onClick={onPresentConnectModal}>
              {t('Unlock Wallet')}
            </Button>
          )}
        </div>
      </div>
    </OverlayContainer>
  )
}

export default MobileOverlayMenu
