import { createGlobalStyle } from 'styled-components'
// eslint-disable-next-line import/no-unresolved
import { SiteTheme } from 'ui-kit/theme'

declare module 'styled-components' {
  /* eslint-disable @typescript-eslint/no-empty-interface */
  export interface DefaultTheme extends SiteTheme {}
}

const GlobalStyle = createGlobalStyle`
  * {
    font-family: 'Roboto', sans-serif;
  }
  body {
    //background-color: ${({ theme }) => theme.colors.background};

    img {
      height: auto;
      max-width: 100%;
    }
  }

  @font-face {
    font-family: Jammycreamer;
    src: url(jammycreamer.regular.ttf);
  }
`

export default GlobalStyle
