import React from 'react'
import { useTranslation } from 'contexts/Localization'
import RoadMap from 'views/RoadMap/RoadMap'
import { HashLink as Link } from 'react-router-hash-link'
import MenuItems from 'config/menu/menu-items.json'
import FooterStyledWrapper from './FooterStyles'

const Footer = () => {
  const { t } = useTranslation()
  return (
    <div id="road-map">
      <FooterStyledWrapper>
        <img className="footer-image" src="/images/footer_ver1.png" alt="footer" />
        <div className="road-map-wrapper">
          <RoadMap />
        </div>
        <div className="wrapper">
          <div className="footer-wrapper">
            <div className="footer-item-wrapper">
              {MenuItems.map((item) => (
                <Link
                  className="footer-item"
                  smooth
                  key={item.id}
                  to={item.to}
                  scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}
                >
                  {t(item.text)}
                </Link>
              ))}
            </div>
            <span className="copyright">FarmLand 2022 ©</span>
          </div>
        </div>
      </FooterStyledWrapper>
    </div>
  )
}

export default Footer
