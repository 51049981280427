/* eslint-disable jsx-a11y/alt-text */
import React from 'react'
import ProgressBarStylesWrapper from './ProgressBarStyles'

const ProgressBar = ({ width = 50, text }) => {
  return (
    <ProgressBarStylesWrapper>
      <span className="bar-text">{text}</span>
      <div className="percent" style={{ width: `${width}%` }} />
    </ProgressBarStylesWrapper>
  )
}

export default ProgressBar
