import {ChainId} from './types'

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'

// TODO: replace mullitcall and nft and specify other contracts
// TODO: replace mainnet contracts
export default {
  mulltiCall: { // mulltiCall irrevant with this project to be removed
    [ChainId.TESTNET]: '0xb465Fd2d9C71d5D6e6c069aaC9b4E21c69aAA78f',
    [ChainId.MAINNET]: '0x98e2060F672FD1656a07bc12D7253b5e41bF3876',
  },
  NFT: { // NFT native
    [ChainId.TESTNET]: '0xfF0E7eA8A836BBE5b2e73Eb157Cfcd459D805081',
    [ChainId.MAINNET]: '0xfF0E7eA8A836BBE5b2e73Eb157Cfcd459D805081',
  },
  Land: {
    [ChainId.TESTNET]: '0xeFF5F14A3Caf2fB8C73a3c011004063a183800b8',
    [ChainId.MAINNET]: '0xeFF5F14A3Caf2fB8C73a3c011004063a183800b8',
  },
  Farm: {
    [ChainId.TESTNET]: '0x39bbA4b53c8740feD2349f0f162432460209C491',
    [ChainId.MAINNET]: '0x39bbA4b53c8740feD2349f0f162432460209C491',
  },
  Crop: {
    [ChainId.TESTNET]: '0x865FCAE19e52a332343617bcfcA7fB8d1044635A',
    [ChainId.MAINNET]: '0x865FCAE19e52a332343617bcfcA7fB8d1044635A',
  },
  Water: {
    [ChainId.TESTNET]: '0x147BB811cE1F17922C952CCaA0d0b60fA0aA8b54',
    [ChainId.MAINNET]: '0x147BB811cE1F17922C952CCaA0d0b60fA0aA8b54',
  },
  Spell: {
    [ChainId.TESTNET]: '0x86af30dB34863D9833AE44eAA50675Cc3FeB1A95',
    [ChainId.MAINNET]: '0x86af30dB34863D9833AE44eAA50675Cc3FeB1A95',
  },
  Crafter: {
    [ChainId.TESTNET]: '0xa9cea9c1eF19635bA74355d09077dFC98965ffA3',
    [ChainId.MAINNET]: '0xa9cea9c1eF19635bA74355d09077dFC98965ffA3',
  },
  Bank: {
    [ChainId.TESTNET]: '0x0c0B45B82007b2C884fe61E458cAE6C798E8cDA0',
    [ChainId.MAINNET]: '0x0c0B45B82007b2C884fe61E458cAE6C798E8cDA0',
  },
  Farmer: {
    [ChainId.TESTNET]: '0x419C7B6f6c2824c038C3bb7289DDb9579f024e4a',
    [ChainId.MAINNET]: '0x419C7B6f6c2824c038C3bb7289DDb9579f024e4a',
  },
  FarmerTrait: {
    [ChainId.TESTNET]: '0x3F09a41e2B3A3274BE6546B975B826093262fF4D',
    [ChainId.MAINNET]: '0x3F09a41e2B3A3274BE6546B975B826093262fF4D',
  },
  RandomSource: {
    [ChainId.TESTNET]: '0x5329b155432530abeCD13aF136f18bEBDEA7658a',
    [ChainId.MAINNET]: '0x5329b155432530abeCD13aF136f18bEBDEA7658a',
  },
}
