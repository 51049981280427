import styled from 'styled-components'

export default styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  background-image: url('/images/progressbar.png');
  background-size: contain;
  background-repeat: no-repeat;
  max-width: 400px;
  background-position: center;
  position: relative;

  .bar-text {
    font-size: 22px;
    z-index: 1;
  }

  .percent {
    position: absolute;
    max-width: 93%;
    height: 40px;
    width: 100%;
    border-radius: 20px;
    left: 0.6em;
    background-image: linear-gradient(to bottom right, #ffcb2a, #ff8c00);
  }
`
