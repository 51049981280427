import React,{useState, useEffect} from 'react'
import styled from 'styled-components'
import Page from './layout/Page'
import ProgressBar from './ProgressBar/ProgressBarView'

const Wrapper = styled(Page)`
  max-width: 100%;
  background-position: center top;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('/images/loading/loading_min_v2.jpg');
  filter: blur(8px);
`

const ProgressBarDiv = styled.div`
  filter: blur(0px);
  position: fixed;
  top: 45vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
  display: flex;
`

const PageLoader: React.FC = () => {

  const [progress, setProgress] = useState(0)

  useEffect(() => {
    const timer = setTimeout(() => {
      if(progress < 100)
        setProgress(progress+1)
    }, 10)
    return () => clearTimeout(timer)
  }, [setProgress,progress])

  return (<>
      <Wrapper />
      <ProgressBarDiv>
        <ProgressBar text="" width={progress} />
      </ProgressBarDiv>
  </>
  )
}

export default PageLoader
