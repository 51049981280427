/* eslint-disable react/jsx-curly-brace-presence */
import React from 'react'
import styled from 'styled-components'
import RoadMapStyles from './RoadMapStyles'

const Title = styled.div`
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: 'Jammycreamer', sans-serif;
  color: white;
  font-weight: 500;
  font-size: 8vw;

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: min(4.5vw, 5em);
    margin-bottom: 0;
  }
`

const ScrollToSeeMore = styled.div`
  text-align: center;
  color: white;
  font-weight: 200;
  font-size: 12px;

  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 14px;
  }
`

const RoadMap = () => {
  return (
    <div id="roadmap">
      <Title className="title">ROADMAP</Title>
      <ScrollToSeeMore>↔️ scroll right to see more</ScrollToSeeMore>
      <RoadMapStyles>
        <div className="scrollmenu">
          <div className="road-map-item">
            <div className="road-map-image">
              <span className="item-text">Hello World</span>
            </div>
            <div className="map-description">
              The first announcement of FarmLand and beginning of community creation.
            </div>
          </div>
          <div className="road-map-item">
            <div className="road-map-image">
              <span className="item-text">Website Launch</span>
            </div>
            <div className="map-description">Launch of landing page of FarmLand.</div>
          </div>
          <div className="road-map-item">
            <div className="road-map-image">
              <span className="item-text">WL Spots Dist.</span>
            </div>
            <div className="map-description">
              Distribution of whitelist spots to our community members with Discord and Twitter events.
            </div>
          </div>
          <div className="road-map-item">
            <div className="road-map-image">
              <span className="item-text">Minting Day</span>
            </div>
            <div className="map-description">
              60k Lands and 10k Farmers will be available to mint. 50% of minting will be done with AVAX and the rest
              with AVAX/$FARM LP tokens.
            </div>
          </div>

          <div className="road-map-item">
            <div className="road-map-image">
              <span className="item-text">$FARM LP</span>
            </div>
            <div className="map-description">
              LP Creation of FARM token to enable trade with AVAX/FARM and continue minting.
            </div>
          </div>

          <div className="road-map-item">
            <div className="road-map-image">
              <span className="item-text">NFT Marketplaces</span>
            </div>
            <div className="map-description">
              Our beautiful Land and Farmers will be tradable on prominent NFT Marketplaces.
            </div>
          </div>

          <div className="road-map-item">
            <div className="road-map-image">
              <span className="item-text">Game Launch</span>
            </div>
            <div className="map-description">
              Let the game begin! Time to stake your Lands and Farmers to the gamification and get $FARM rewards!
            </div>
          </div>

          <div className="road-map-item">
            <div className="road-map-image">
              <span className="item-text">Water token</span>
            </div>
            <div className="map-description">
              Water is source of life and Water token is key of to get more an more $FARM rewards.
            </div>
          </div>
          <div className="road-map-item">
            <div className="road-map-image">
              <span className="item-text">Land upgrade</span>
            </div>
            <div className="map-description">
              Land upgrade is one of the most promising features of FarmLand ecosystem and will allow you to merge your
              Land NFTs using $BERRY token to fill your available Land slots with higher Lands and get more $BERRY.
            </div>
          </div>
          <div className="road-map-item">
            <div className="road-map-image">
              <span className="item-text">Multi farmer</span>
            </div>
            <div className="map-description">
              A single farmer is never enough. Stake multiple farmers get more $BERRY and eventually get more $FARM.
            </div>
          </div>

          <div className="road-map-item">
            <div className="road-map-image">
              <span className="item-text">Spell Token</span>
            </div>
            <div className="map-description">
              Do not want to take risk while upgrading Lands? Spell token is will magically guarantee you to get greater
              land while upgrading Lands.
            </div>
          </div>

          <div className="road-map-item">
            <div className="road-map-image">
              <span className="item-text">Season 2 👀</span>
            </div>
            <div className="map-description">
              Improved game mechanics, new NFT tools to stake and much more. We will play together, we will grow
              together.
            </div>
          </div>
        </div>
      </RoadMapStyles>
    </div>
  )
}

export default RoadMap
