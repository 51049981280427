import styled from 'styled-components'
import { HashLink as Link } from 'react-router-hash-link'

export const HeaderLayout = styled.div`
  justify-content: space-between;
  align-items: center;
  height: 150px;
  flex-direction: row;
  display: none;

  ${({ theme }) => theme.mediaQueries.md} {
    display: flex;
  }
`

export const LogoContainer = styled(Link)`
  width: 150px;
  height: 150px;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 150px;
    height: 150px;
  }
`

export const Logo = styled.img`
  width: 100%;
  padding: 10px;
`

export const SocialLogoContainer = styled.a`
  height: 40px;
  width: 50px;
  display: flex;
  border: 3px solid #000;
  border-radius: 50px;
  box-shadow: 3px 3px 0px 0px #333;
  background: #ffffff8b;
  padding: 5px 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    transform: rotate(2.69deg);
  }

  &:active {
    box-shadow: 1px 1px 0px 0px #333;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 10px 15px;
  }
`

export const SocialLogo = styled.img`
  width: 70%;
  padding: 10px;
  max-height: 40px;

  ${({ theme }) => theme.mediaQueries.md} {
    width: 100%;
  }
`

export const HeaderLeftContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 15%;
  height: 100%;
  align-items: left;
  justify-content: start;
`

export const HeaderCenterContainer = styled.div`
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`

export const HeaderRightContainer = styled.div`
  width: 15%;
  display: flex;
  align-items: center;
  width: 15%;
  justify-content: flex-end;
  height: 100%;
`

export const HeaderLink = styled(Link)`
  cursor: pointer;
  font-weight: bold;
  font-family: 'Poppins', sans-serif;
  color: #0f2e00;
  text-decoration: none;
  white-space: nowrap;
  &:hover {
    transform: rotate(2.69deg);
  }
  ${({ theme }) => theme.mediaQueries.md} {
    font-size: 15px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    font-size: 18px;
  }
`
