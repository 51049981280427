import styled from 'styled-components'

const AppContainer = styled.div`
  width: 100vw;
  min-width: 100%;
  height: 100vh;
  min-height: 100%;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
`

export default AppContainer
