import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Layout = styled.div`
  justify-content: space-between;
  display: flex;
  height: 100px;
  padding: 0px 10px;
  flex-direction: row;
  align-items: center;

  .fas {
    font-size: 2rem;
    color: #222;
  }

  .fas:hover {
    color: ${({ theme }) => theme.colors.primary};
  }

  .menu {
    right: 30px;
  }

  #menuBtn {
    display: none;
  }

  ${({ theme }) => theme.mediaQueries.xxs} {
    display: flex;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    display: none;
  }
`

export const LogoContainer = styled(Link)`
  width: 100px;
  height: 100%;
`

export const Logo = styled.img`
  width: 100%;
  padding: 10px;
`
