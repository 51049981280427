import { Colors } from './types'

// Yellow #F9D92E
// Red #E60040
// Blue #15B0F8
// Purple #9027E2
// Pink #D4008F
// Dark blue #2F1B6D
// f8c501

export const baseColors = {
  primary: '#f8c501',
  primaryBright: '#F9F501',
  primaryDark: '#350000',
  secondary: '#E60C41',
  success: '#9CD6D0',
  desc: '#000',
  warning: '#F7C500',
  failure: '#D33B26',
}

export const brandColors = {
  avalanche: '#e84142',
}

export const lightColors: Colors = {
  ...baseColors,
  ...brandColors,
  background: '#7CAD12',
  backgroundDisabled: '#749075',
  contrast: '#FFFFFF',
  dropdown: '#4E0000',
  dropdownDeep: '#100C18',
  invertedContrast: '#191326',
  input: '#483f5a',
  inputSecondary: '#66578D',
  button: '#f8c501',
  tertiary: '#681900',
  text: '#fff8d2',
  textDisabled: '#666171',
  textSubtle: '#a0e4f1',
  borderColor: '#C86F00',
  card: '#813200',
  cardBorder: '#C86F00',
  alwaysWhite: '#fff8d2',
  gradients: {
    bubblegum: 'linear-gradient(139.73deg, #d4008f4a 0%, #d4008f00 100%)',
    cardHeader: 'linear-gradient(139.73deg, #d4008f4a 0%, #d4008f00 100%)',
    blue: 'linear-gradient(180deg, #00707F 0%, #19778C 100%)',
    violet: 'linear-gradient(180deg, #6C4999 0%, #6D4DB2 100%)',
    violetAlt: 'linear-gradient(180deg, #434575 0%, #66578D 100%)',
  },
}

// no dark theme for now.
export const darkColors = lightColors
