import addresses from 'config/constants/contracts'
import { Address, ChainId } from 'config/constants/types'

export const getAddress = (address: Address): string => {
  const mainNetChainId = ChainId.MAINNET
  const chainId = process.env.REACT_APP_CHAIN_ID
  return address[chainId] ? address[chainId] : address[mainNetChainId]
}

export const getMulticallAddress = () => {
  return getAddress(addresses.mulltiCall)
}

export const getNftAddress = () => {
  return getAddress(addresses.NFT)
}

export const getLandNftAddress = () => {
  return getAddress(addresses.Land)
}

export const getFarmAddress = () => {
  return getAddress(addresses.Farm)
}

export const getCropAddress = () => {
  return getAddress(addresses.Crop)
}

export const getSpellAddress = () => {
  return getAddress(addresses.Spell)
}

export const getCrafterAddress = () => {
  return getAddress(addresses.Crafter)
}

export const getBankAddress = () => {
  return getAddress(addresses.Bank)
}

export const getRandomSourceAddress = () => {
  return getAddress(addresses.RandomSource)
}

export const getWaterAddress = () => {
  return getAddress(addresses.Water)
}

export const getFarmerAddress = () => {
  return getAddress(addresses.Farmer)
}

export const getFarmerTraitAddress = () => {
  return getAddress(addresses.FarmerTrait)
}

export const truncateAddress = (address: string, maxSize=10) => (
  address.length > 10 ? `${address.substring(0, maxSize-5)}...${address.slice(address.length-3)}` : address
)
