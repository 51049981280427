
export enum ChainId {
  TESTNET = 43113,
  MAINNET = 43114
}

export interface Address {
  [ChainId.TESTNET]?: string
  [ChainId.MAINNET]: string
}

export enum ConnectorNames {
  Injected = 'injected',
}