/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import logo from '../../../assets/logo.png'

import {
  Layout,
  Logo,
  LogoContainer,
} from './layouts'

const HeaderMobile = () => {
  return (
    <Layout>
        <LogoContainer to="/">
            <Logo src={logo} alt="logo" />
        </LogoContainer>
        <div className="menu">
            <label htmlFor="menuBtn">
              <i className="fas fa-bars" />
            </label>
          </div>
    </Layout>
  )
}

export default HeaderMobile
