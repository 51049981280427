// @ts-nocheck
import React, { useEffect, lazy } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import BigNumber from 'bignumber.js'
import useEagerConnect from 'hooks/useEagerConnect'
import Header from 'ui-kit/components/Header'
import Footer from 'ui-kit/components/Footer'
import AppContainer from 'ui-kit/components/AppContainer'
import MobileOverlayMenu from 'ui-kit/components/HeaderMobile/MobileOverlayMenu'
import smoothscroll from 'smoothscroll-polyfill'
import GlobalStyle from './style/Global'
import SuspenseWithChunkError from './components/SuspenseWithChunkError'
import ToastListener from './components/ToastListener'
import PageLoader from './components/PageLoader'
import history from './routerHistory'

// Route-based code splitting
const LandingPage = lazy(() => import('pages/LandingPage'))
const NotFound = lazy(() => import('./views/NotFound'))

// This config is required for number formating
BigNumber.config({
  EXPONENTIAL_AT: 1000,
  DECIMAL_PLACES: 80,
})

const App: React.FC = () => {
  smoothscroll.polyfill()

  // Monkey patch warn() because of web3 flood
  // To be removed when web3 1.3.5 is released
  useEffect(() => {
    console.warn = () => null
  }, [])

  useEagerConnect()

  return (
    <Router history={history}>
      <GlobalStyle />
      <SuspenseWithChunkError fallback={<PageLoader />}>
        <MobileOverlayMenu />
        <AppContainer>
          <Header />
          <Switch>
            <Route path="/" exact component={LandingPage} />
            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
          <Footer />
          {/* <SkyBackground /> */}
        </AppContainer>
      </SuspenseWithChunkError>
      <ToastListener />
    </Router>
  )
}

export default React.memo(App)
